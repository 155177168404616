<template>
  <el-row class="movie-list">
    <el-col :md="20">
      <el-row>
        <el-table
          ref="multipleTable"
          :data="dataList"
          :show-header="true"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
          />
          <el-table-column
            fixed="left"
            label="商品信息"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col :md="4">
                  <el-image :src="scope.row.picUrl" min-width="30" height="20" />
                </el-col>
                <el-col :md="20">
                  <router-link target="_blank" style="text-decoration-line: none" :to="`/mall/item?id=${scope.row.itemId}`">
                    <span>{{scope.row.title}}</span>
                  </router-link>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="sku"
          />
          <el-table-column
            label="单价"
          >
            <template slot-scope="scope">
              <span style="color: red">￥{{scope.row.price}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="数量"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.num" @change="handleNumChange(scope.row)" :min="1" :max="10" label="描述文字"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="collect(scope.row)"
              >移入关注</el-button>
              <el-button
                size="mini"
                @click="deleteOne(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
<!--        <el-pagination
          background
          :small="screenWidth <= 768"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalSize"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
        />-->
        <el-divider/>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-button size="mini" type="plain" @click="deleteMultiple">快速清理</el-button>
            <el-button size="mini" type="plain" @click="deleteMultiple">移入关注</el-button>
            <el-button size="mini" type="plain" @click="deleteMultiple">删除</el-button>
          </el-form-item>
          <el-form-item>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              已选商品 <span style="color: red">{{multipleSelection.length}}</span> 件
            </span>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              合计（不含运费）： <span style="color: red">{{totalPrice}}</span> 元
            </span>
            <el-button size="mini" type="danger" @click="buy">结算</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import {deleteFromCart, getCartItems, updateCart} from "@/api/mall";

export default {
  name: 'Cart',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 10,
      totalSize: 0,
      dataList: [],
      checked: false,
      multipleSelection: [],
      totalSelected: 0,
      totalPrice: 0.00
    }
  },
  created() {
    document.title = '购物车'
    this.getData()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
    },
    getData() {
      getCartItems().then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    collect(row) {
    },
    deleteOne(row) {
      deleteFromCart(row.itemId).then(resp => {
        if (resp.code === 0) {
          this.dataList.pop()
        }
      })
    },
    handleSelectionChange(val) {
      this.totalPrice = 0.00
      this.multipleSelection = val
      for (const item of val) {
        this.totalPrice += item.price * item.num
      }
    },
    deleteMultiple() {
      if (this.multipleSelection.length === 0) {
        this.$notify({
          message: '至少应选中一行',
          type: 'warning',
          duration: 3000
        })
        return
      }

      var fileIds = []
      for (const item of this.multipleSelection) {
        fileIds.push(item.fileId)
      }

      this.$confirm('确定要删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleNumChange(value) {
      const jsonData = {}
      jsonData.itemId = value.itemId
      jsonData.num = value.num
      updateCart(jsonData).then(resp => {
        if (resp.code === 0) {
        }
      })
    },
    buy() {
      var items = []
      for (const item of this.multipleSelection) {
        items.push(item)
      }

      window.sessionStorage.setItem('cart0', JSON.stringify(items))
      this.$router.push({
        path: '/mall/order/confirm',
        query: {
          pageNumber: 1
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
